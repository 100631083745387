<template>
  <TableViewWithTitle title="첨부파일">
    <template v-slot:colgroup>
      <col />
      <col />
    </template>

    <template v-slot:body>
      <tr>
        <th>첨부파일</th>
        <td>
          <FileWrite
            :dataList="model.attachFileList"
            @fileUploaded="fileUploaded"
            @fileRemoved="fileRemoved"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileWrite from "@/components/common/file/FileWrite";

export default {
  name: "DraftFile",
  components: {
    FileWrite,
    TableViewWithTitle,
  },
  props: {
    model: Object,
  },
  data() {
    return {};
  },
  methods: {
    fileUploaded(files) {
      this.model.attachFileList = files;
    },
    fileRemoved(itemToRemove) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== itemToRemove);
    },
  },
};
</script>
