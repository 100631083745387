<template>
  <TableViewWithTitle title="구매요청 정보">
    <template v-slot:body>
      <tr>
        <th>
          구매요청 연결
          <span class="ico_purchase ico_star">필수항목</span>
        </th>
        <td colspan="3">
          <div class="group_form">
            <button
              type="button"
              class="btn_fourthly btn_small"
              @click="$emit('onClickPurchaseRequestesSearch')"
            >
              구매요청 정보 불러오기
            </button>
          </div>
          <ConnectEditOnlyOne
            :dataList="model.purchaseContract"
            @onClickConnect="(item) => $emit('onClickConnectPurchaseView', item)"
            @onClickRemove="(item) => $emit('onClickRemovePurchaseReqeust', item)"
          />
        </td>
      </tr>
      <tr>
        <th>프로젝트 코드</th>
        <td>
          {{ model.projectCode }}
        </td>
        <th>프로젝트 명</th>
        <td v-html="model.projectName"></td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ConnectEditOnlyOne from "@/components/common/connect/ConnectEditOnlyOne";

export default {
  name: "DraftPurchaseInfo",
  components: {
    TableViewWithTitle,
    ConnectEditOnlyOne,
  },
  props: {
    model: Object,
  },
  created() {},
  methods: {},
};
</script>
