<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">기안 등록</h2>
      </div>
      <div class="body_section">
        <!-- 구매요청 불러오기 -->
        <DraftPurchaseInfo
          :model="model"
          @onClickPurchaseRequestesSearch="onClickPurchaseRequestesSearch"
          @onClickConnectPurchaseView="onClickConnectPurchaseView"
          @onClickRemovePurchaseReqeust="onClickRemovePurchaseReqeust"
        />
        <!-- 기안 정보 -->
        <DraftInfo ref="draftInfo" :model.sync="model" />
        <TableViewWithTitle title="구매구분">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <th>구매구분</th>
            <td>
              {{ model.assetTypeName | nullToDash }}
            </td>
          </template>
        </TableViewWithTitle>
        <!-- 일반구매 금액 -->
        <!-- <DraftPriceNormal
          v-if="model.assetType === 'H'"
          ref="DraftPriceNormal"
          :model.sync="model"
          @onClickSearchProjectCode="onClickProjectCodePopup"
        /> -->

        <!-- 자산구매 금액 -->
        <DraftPriceInfra ref="DraftPriceInfra" :model.sync="model" />

        <!-- 상세내용 -->
        <div class="area_view">
          <TableHead title="상세내용" />
          <div class="editor_comm">
            <!-- method=""-->
            <form>
              <textarea id="summernote" name="editordata" />
            </form>
          </div>
        </div>

        <!-- 첨부파일 -->
        <DraftFile :model.sync="model" />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickCancel">취소</button>
      </div>
      <div class="area_right">
        <button v-if="!isModifyMode" class="btn_primary btn_large" @click="onClickSaveComfirm">
          기안등록
        </button>
        <button v-else class="btn_primary btn_large" @click="onClickUpdateComfirm">기안수정</button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isSaveComfirm"
        alertText="기안을 등록하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="onClickSaveConfirm"
      />
      <AlertPopup
        v-if="isUpdateComfirm"
        alertText="기안을 수정하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="onClickUpdateConfirm"
      />

      <AlertPopup
        v-if="isAlertCancel"
        alertText="기안 작성을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickAlertCancel"
        @onClickPrimary="onClickAlertOk"
      />

      <PurchaseRequestsPopup
        v-if="isPopPurchaseRequests"
        :purchaseWfDataList.sync="model.purchaseContract"
        :type="'C'"
        @setPurchaseContract="setPurchaseContract"
        @onClickClose="onClickClosePopPurchaseRequests"
      />

      <!--@onClickComplete="onClickCompleteProjectCodePopup"-->
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import TableHead from "@/components/shared/TableHead";
import Sticky from "@/components/layout/content/Sticky";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

import DraftPurchaseInfo from "@/components/admin/draft/write/DraftPurchaseInfo";
import DraftInfo from "@/components/admin/draft/write/DraftInfo";
import DraftPriceNormal from "@/components/admin/draft/write/DraftPriceNormal";
import DraftPriceInfra from "@/components/admin/draft/write/DraftPriceInfra";
import DraftFile from "@/components/admin/draft/write/DraftFile";

import DraftModel from "./DraftModel";
import DraftPurchasePriceListModel from "@/components/admin/draft/write/DraftPurchasePriceListModel";
import DraftPurchaseGoodsListModel from "@/components/admin/draft/write/DraftPurchaseGoodsListModel";

import PurchaseRequestsPopup from "@/components/shared/purchaseRequestPopup/PurchaseRequestsPopup.vue";

import PopGoodsNameSearch from "@/components/admin/draft/write/popup/PopGoodsNameSearch.vue";

import { CATEGORY_ACTION } from "@/store/modules/category/action";
import { DRAFT_WRITE_ACTION } from "@/store/modules/draft/action";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { escapeRegExp } from "@/utils/spUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";
import ReferenceMixin from "@/mixins/ReferenceMixin";

export default {
  name: "DraftWrite",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    TableHead,
    Sticky,
    AlertPopup,

    DraftPurchaseInfo,
    DraftInfo,
    DraftPriceNormal,
    DraftPriceInfra,
    DraftFile,

    PurchaseRequestsPopup,

    PopGoodsNameSearch,
  },
  mixins: [PageMixin], //ReferenceMixin
  data() {
    return {
      isModifyMode: false,
      type: "",
      requestCid: "",

      model: new DraftModel(),
      newNumber: "", // 입찰 공고 번호
      purchaseWfPostList: [],
      selectedGoodsName: {},
      productInfo: {
        goods: "",
        amount: "",
        currency: "KRW",
        quantity: "EA",
        budget: "",
      },
      isAlertCancel: false,
      isSaveComfirm: false,
      isUpdateComfirm: false,
      isPopPurchaseRequests: false,
    };
  },
  computed: {},
  created() {
    this.isModifyMode = Boolean(this.$route.meta.isModifyMode);
    this.model.itemId = this.$route.params.id;

    if (this.isModifyMode) {
      const path = `${this.$apiPath.ST_PURCHASE_DRAFT}/${this.model.itemId}`;
      this.$store.dispatch(PROGRESS_START_ACTION);
      this.getData(path);
    } else {
      const { requestCid, type } = this.$route.params;
      if (requestCid) {
        const { requestCid, type } = this.$route.params;
        this.requestCid = requestCid;
        this.type = type;
        this.getPurchaseRequest();
      }
    }
  },

  mounted() {
    this.createSummernote();
  },
  beforeDestroy() {
    const summernote = $("#summernote");
    if (summernote) summernote.summernote("destroy");
  },
  methods: {
    async getPurchaseRequest() {
      // 구매요청상세에서 계약관리 버튼 눌어 들어 왓을떄에 데이터 처리
      // 구매요청서 선택시 데이터 처리
      const path = `${this.$apiPath.ST_COMM_REQUEST}/${this.type}/${this.requestCid}`;
      const result = await ApiService.shared.getData(`${path}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.model.setConvertPurchaseData(data.purchaseRequestDetail);
    },
    async getData(path) {
      const result = await ApiService.shared.getData(path);

      this.$store.dispatch(PROGRESS_END_ACTION);
      if (result.code !== "200") {
        this.alert(result.text);

        return;
      }
      this.model.setData(result.data);
    },

    onClickSaveConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.saveDraft();
    },
    async saveDraft() {
      const obj = this.model.getData();
      //등록

      const result = await ApiService.shared.post(this.$apiPath.ST_PURCHASE_DRAFT, obj);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      const draftCid = data.draftCid;
      this.$router.push({
        path: `${this.$routerPath.DRAFT_VIEW}/${draftCid}`,
      });
    },
    onClickUpdateConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.updateDraft();
    },
    async updateDraft() {
      const obj = this.model.getData();
      //수정
      const result = await ApiService.shared.putData(
        `${this.$apiPath.ST_PURCHASE_DRAFT}/${this.model.draftCid}`,
        obj,
      );

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      const draftCid = data.draftCid;
      this.$router.push({
        path: `${this.$routerPath.DRAFT_VIEW}/${draftCid}`,
      });
    },

    onClickCancel() {
      this.isAlertCancel = true;
    },
    onClickAlertOk() {
      if (this.isModifyMode) {
        this.$router.push({
          path: `${this.$routerPath.DRAFT_VIEW}/${this.model.draftCid}`,
        });
      } else {
        this.$router.push({
          path: this.$routerPath.DRAFT_LIST,
        });
      }
    },
    onClickAlertCancel() {
      this.isAlertCancel = false;
    },
    onClickSaveComfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isSaveComfirm = true;
    },
    onClickUpdateComfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isUpdateComfirm = true;
    },
    onClickCloseAlertComfirm() {
      this.isSaveComfirm = false;
      this.isUpdateComfirm = false;
    },
    onClickRemove(itemToRemove) {
      this.requestPostList = this.requestPostList.filter((item) => item !== itemToRemove);
    },

    // 구매요청서 정보 불러오기
    onClickPurchaseRequestesSearch() {
      this.isPopPurchaseRequests = true;
    },
    // 구매요청서 선택 팝업 닫기
    onClickClosePopPurchaseRequests() {
      this.isPopPurchaseRequests = false;
    },
    // 구매요청서 항목 연결
    onClickConnectPurchaseView(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },
    // 구매요청서 삭제
    onClickRemovePurchaseReqeust(item) {
      this.model = new DraftModel();
      $("#summernote").summernote("reset");
    },
    setPurchaseContract(item) {
      const { requestCid } = item;
      this.requestCid = requestCid;
      this.type = "purchase";
      this.getPurchaseRequest();
    },
  },
};
</script>
