import { getMoneyToNumber } from "@/utils/stringUtils";

export default class DraftPurchasePriceListModel {
  constructor(obj) {
    this.objRaw = {};

    this.objItemData = {};
    this.note = "";
    this.draftingPrice = ""; // 기안금액
    this.projectCode = ""; // 프로젝트코드
    this.projectName = ""; // 프로젝트명
    this.assetsCode = ""; //

    this.addPrice = ""; // 추가 금액

    if (obj) {
      this.setData(obj);
    }
  }

  setItemData(obj) {
    this.objItemData = obj;

    const { draftingPrice, projectCode, projectName, addPrice } = obj;

    this.draftingPrice = draftingPrice || draftingPrice === 0 ? String(draftingPrice) : "";
    this.projectCode = projectCode || "";
    this.projectName = projectName || "";
    this.addPrice = addPrice + "" || "0";
  }

  setData(obj) {
    this.objRaw = obj;

    const { draftingPrice, projectCode, projectName, addPrice, note, assetsCode } = obj;

    this.draftingPrice = draftingPrice || draftingPrice === 0 ? String(draftingPrice) : "";
    this.projectCode = projectCode || "";
    this.projectName = projectName || "";
    this.assetsCode = assetsCode || "";
    this.addPrice = addPrice + "" || "0";
    this.note = note || "";
  }

  getData() {
    let obj = {
      draftingPrice: getMoneyToNumber(this.draftingPrice),
      projectCode: this.projectCode,
      projectName: this.projectName,
      assetsCode: this.assetsCode,
      noth: this.note,
    };

    return Object.assign({}, this.objItemData, this.objRaw, obj);
  }

  getAlertMessage() {
    // if (this.draftingPrice === 0 || this.draftingPrice === '0' || !this.draftingPrice)
    //   return '품목의 수량을 확인해주세요.';
    // if (this.unitPrice !== 0 && !this.unitPrice)
    //   return '품목의 단가를 확인해주세요.';

    return null;
  }

  isEmpty() {
    return !this.draftingPrice || !this.projectCode;
  }
}
