import { getMoneyToNumber } from "@/utils/stringUtils";

export default class DraftPurchaseGoodsListModel {
  constructor(obj) {
    this.objRaw = {};

    this.quantity = "1"; // 수량
    this.itemCode = ""; // 품목코드
    this.itemName = ""; // 품명
    this.displayCode = "";
    this.itemName = ""; // 품명
    this.note = ""; // 비고
    this.assetsCode = ""; // 자산코드
    this.totalPrice = ""; // 금액
    this.projectCode = ""; // 프로젝트코드
    this.projectName = ""; // 프로젝트명
    this.price = ""; // 단가
    this.unitType = ""; // 단위코드(EA, Copy, 식, 직접입력)
    this.unitValue = ""; // 단위값(직접입력 단위값)
    this.accountCode = ""; // 계정 코드
    this.accountName = ""; // 계정명
    this.assetInfoNum = ""; // 자산정보 아이디
    this.warranty = ""; // 보증기간
    this.assetStatus = ""; // 자산상태
    this.cmdbAssetInfo = {}; // cmbd 자산정보

    this.unitType = "E"; //단위
    this.unitValue = "EA"; //단위
    if (obj) {
      this.setData(obj);
    }
  }

  setItemData(obj) {
    this.objRaw = _.cloneDeep(obj);

    const {
      cateNum,
      category,
      category0,
      category1,
      category2,
      displayCode,
      itemCode,
      itemName,
      regDate,
      standard,
      status,
      statusName,
      assetsCode,
      unitType,
      unitValue,
      projectCode,
      projectName,
    } = obj;

    this.itemCode = itemCode || "";
    this.itemName = itemName || "";
    this.displayCode = displayCode || "";
    this.unitType = unitType + "" || "E";
    this.unitValue = unitValue + "" || "EA";
    this.projectCode = projectCode || "-";
    this.projectName = projectName || "-";
    this.assetsCode = assetsCode || "";
  }

  setData(obj) {
    this.objRaw = obj;

    const {
      quantity,
      itemCode,
      displayCode,
      itemName,
      assetsCode,
      note,
      totalPrice,
      projectCode,
      projectName,
      price,
      unitType,
      unitValue,
      assetInfoNum,
      cmdbAssetInfo,
    } = obj;

    this.quantity = quantity || quantity === 0 ? String(quantity) : "";
    this.itemCode = itemCode || "";
    this.itemName = itemName || itemName || "";
    this.displayCode = displayCode || "";
    this.note = note || "";
    this.totalPrice = totalPrice || totalPrice === 0 ? String(totalPrice) : "";
    this.projectCode = projectCode || "-";
    this.projectName = projectName || "-";
    this.price = price || price === 0 ? String(price) : "";
    this.unitType = unitType + "" || "E";
    this.unitValue = unitValue + "" || "EA";
    this.assetsCode = assetsCode || "";
    this.assetInfoNum =
      cmdbAssetInfo && cmdbAssetInfo.assetInfoNum ? cmdbAssetInfo.assetInfoNum : assetInfoNum;

    if (cmdbAssetInfo) {
      this.accountCode = cmdbAssetInfo.accountCode;
      this.accountName = cmdbAssetInfo.accountName;
      this.cmdbAssetInfo = cmdbAssetInfo;
    }
  }

  changeCalcPrice() {
    const { quantity, price } = this;

    if (!quantity && !price) return "0";

    const cal = getMoneyToNumber(quantity) * getMoneyToNumber(price);

    this.totalPrice = String(cal);
  }

  getData() {
    let obj = {
      quantity: getMoneyToNumber(this.quantity),
      itemCode: this.itemCode,
      itemName: this.itemName,
      itemName: this.itemName,
      totalPrice: getMoneyToNumber(this.totalPrice),
      projectCode: "-",
      projectName: "-",
      assetsCode: this.assetsCode,
      note: this.note,
      price: getMoneyToNumber(this.price),
      unitType: this.unitType,
      unitValue: this.unitValue,
      accountName: this.accountName,
      accountCode: this.accountCode,
      cmdbAssetInfo: { assetInfoNum: this.assetInfoNum },
    };

    return Object.assign({}, this.objItemData, this.objRaw, obj);
  }

  getAlertMessage() {
    if (this.quantity === 0 || this.quantity === "0" || !this.quantity)
      return "품목의 수량을 확인해주세요.";
    if (this.price !== 0 && !this.price) return "품목의 단가를 확인해주세요.";

    return null;
  }
}
