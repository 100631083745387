<template>
  <TableViewWithTitle title="기안 금액">
    <template v-slot:colgroup>
      <col style="width: 60px" />
      <col />
      <col style="width: 400px" />
      <col style="width: 120px" />
    </template>
    <template v-slot:header>
      <th>No.</th>
      <th>기안금액</th>
      <th>프로젝트 코드</th>
      <th>
        <button class="btn_small btn_secondary" type="button" @click="onClickAddPrice">추가</button>
      </th>
    </template>
    <template v-slot:body>
      <tr v-for="(item, index) in model.purchasePriceList" :key="index">
        <td>{{ index + 1 }}</td>
        <td>
          <InputAutoMoney
            placeholder="기안금액"
            :maxLength="100"
            :value.sync="item.draftingPrice"
          />
        </td>
        <td>
          <div class="group_form">
            <Input
              placeholder="프로젝트 코드"
              :value.sync="item.projectCode"
              :isDisabled="true"
              style="width: 284px"
            />
            <button
              type="button"
              class="btn_small btn_fourthly"
              @click="$emit('onClickSearchProjectCode', index)"
            >
              조회
            </button>
          </div>
        </td>
        <td class="align_center">
          <button
            v-if="index > 0"
            class="btn_small btn_fourthly"
            type="button"
            @click="onClickRemovePrice(item)"
          >
            삭제
          </button>
        </td>
      </tr>
    </template>
    <template v-slot:foot>
      <div class="tbl_foot">
        <div class="area_total">
          <dl>
            <dt>총 기안금액 <span class="txt_info">(부가세 제외)</span></dt>
            <dd>{{ totalDraftingPrice }}</dd>
          </dl>
        </div>
      </div>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import DateTimePicker from "@/components/common/calendar/DateTimePicker";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import SelectBox from "@/components/common/selectBox/SelectBox";

import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import ConnectEdit from "@/components/common/connect/ConnectEdit";
import ConnectView from "@/components/common/connect/ConnectView";

export default {
  name: "DraftPriceNormal",
  components: {
    TableViewWithTitle,
    DateTimePicker,
    Input,
    InputAutoMoney,
    SelectBox,
    CategoryGroupIncludeData,
    ConnectEdit,
    ConnectView,
  },
  props: {
    model: Object,
    newNumber: String,

    purchaseWfDataList: Array,
    requestPostList: Array,
  },
  data() {
    return {
      defalutPriceItem: { price: "0", code: null },
      priceList: [{ price: "0", code: null }],
    };
  },
  computed: {
    assetTypeName() {
      const assetType = this.assetTypeList.find((item) => item.code === this.model.assetType);
      if (!assetType) return "";

      return assetType.name;
    },
    totalDraftingPrice() {
      return this.model.totalDraftingPrice();
    },
  },

  watch: {
    totalDraftingPrice() {},
  },
  methods: {
    setCategoryForce(category0, category1, category2) {
      this.$refs.category.setForce(category0, category1, category2);
    },
    onClickAddPrice() {
      this.model.addPrice();
    },
    onClickRemovePrice(itemToRemove) {
      this.model.removePrice(itemToRemove);
    },
  },
};
</script>
