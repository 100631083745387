import LocalStorageManager from "@/LocalStorageManager";
import { draftConst } from "@/constants/const";
import DraftPurchasePriceListModel from "@/components/admin/draft/write/DraftPurchasePriceListModel";
import DraftPurchaseGoodsListModel from "@/components/admin/draft/write/DraftPurchaseGoodsListModel";

import {
  addCommaForMoney,
  decodeHTMLEntities,
  getIsValidHtml,
  getMoneyToNumber,
} from "@/utils/stringUtils";
import { escapeRegExp } from "@/utils/spUtils";
export default class DraftModel {
  constructor() {
    this.objRaw = {};
    this.draftCid = "";
    this.userData = LocalStorageManager.shared.getUserData();
    this.draftEmpNo = this.userData.empNo;
    this.regEmpNo = "";
    this.itemId = "";
    this.requestCid = "";
    this.title = "";
    this.contents = "";
    this.assetType = "";
    this.assetTypeName = "";

    this.assetTypeList = [
      // { code: assetType.GENERAL_ASSET, name:'총무자산' },
      { code: draftConst.SERVICE_AND_GENERAL_ASSET, name: "일반구매" },
      { code: draftConst.INFRA_ASSET, name: "자산구매" },
    ];
    this.assetType = draftConst.SERVICE_AND_GENERAL_ASSET;

    this.originHandleDataList = []; // 구매카테고리 originlist
    this.handleDataList = []; // 구매카테고리 검색후 보여지는 리스트
    this.itemTitle = "";
    this.handleNum = "";

    this.purchasePriceList = [new DraftPurchasePriceListModel()]; // 기안금액
    this.purchaseGoodsList = [new DraftPurchaseGoodsListModel()]; // 구매요청품목

    this.projectCodeList = [];
    this.attachFileList = [];

    this.purchaseContract = {}; //구매요청서 Data

    this.projectCode = "";
    this.projectName = "";

    this.assetTypeName = "";
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);

    const {
      assetTypeName,
      title,
      contents,
      assetType,
      draftCid,
      handleNum,
      itemTitle,
      purchaseGoodsList,
      purchasePriceList,
      purchaseRequest,
      requestCid,
      totalGoodsPrice,
      totalPrice,
      attachFileList,
      projectCode,
      projectName,
    } = obj;
    this.draftCid = draftCid;
    this.requestCid = requestCid;
    this.purchaseContract = purchaseRequest;
    this.title = title || "";
    this.assetType = assetType;

    this.assetTypeName = assetTypeName;
    this.itemTitle = itemTitle;
    this.handleNum = handleNum;

    this.projectCode = projectCode;
    this.projectName = projectName;

    if (purchasePriceList && purchasePriceList.length > 0) {
      this.purchasePriceList = purchasePriceList.map((item) => {
        const ret = new DraftPurchasePriceListModel();
        ret.setData(item);

        return ret;
      });
    }
    if (purchaseGoodsList && purchaseGoodsList.length > 0) {
      this.purchaseGoodsList = purchaseGoodsList.map((item) => {
        const ret = new DraftPurchaseGoodsListModel();
        ret.setData(item);
        return ret;
      });
    }

    const noteDecoded = decodeHTMLEntities(document, contents);
    $("#summernote").summernote("code", noteDecoded);
    this.attachFileList = attachFileList || [];
  }
  setConvertPurchaseData(obj) {
    const {
      requestCid,
      title,
      assetType,
      requestDeptName,
      requesterId,
      requesterName,
      contents,
      purchaseGoodsList,
      purchasePriceList,
      projectCode,
      projectName,
      assetTypeName,
    } = obj;

    const convertObj = {
      requestCid,
      title,
      assetType,
      requesterId,
      contents: escapeRegExp(contents),
      purchaseGoodsList,
      projectCode,
      projectName,
      assetType,
      assetTypeName,

      purchasePriceList,
      purchaseRequest: {
        title: title,
        requestCid: requestCid,
      },
      userData: {
        username: requesterId,
        name: requesterName,
        companyName: requestDeptName,
      },
    };

    this.setData(convertObj);
  }
  setPurchaseContract(obj) {
    const {
      title,
      contents,
      assetType,
      purchasePriceList,
      purchaseGoodsList,
      projectCode,
      projectName,
    } = obj;

    this.title = title;
    this.assetType = assetType;
    this.projectCode = projectCode;
    this.projectName = projectName;
    if (purchasePriceList && purchasePriceList.length > 0) {
      this.purchasePriceList = purchasePriceList.map((item) => {
        const ret = new DraftPurchasePriceListModel();
        ret.setItemData(item);
        ret.draftingPrice = item.requestPrice;
        return ret;
      });
    }
    if (purchaseGoodsList && purchaseGoodsList.length > 0) {
      this.purchaseGoodsList = purchaseGoodsList.map((item) => {
        const ret = new DraftPurchaseGoodsListModel();
        ret.setItemData(item);
        return ret;
      });
    }
    const noteDecoded = decodeHTMLEntities(document, escapeRegExp(contents));
    $("#summernote").summernote("code", noteDecoded);
  }

  getData() {
    const obj = {
      requestCid: this.purchaseContract.requestCid,
      title: this.title,
      assetType: this.assetType,
      regEmpNo: this.purchaseContract.requesterEmpNo,
      draftEmpNo: this.draftEmpNo,
      handleNum: this.handleNum,
      //purchasePriceList: this.purchasePriceList,
      purchaseGoodsList: this.purchaseGoodsList,
      projectCode: this.projectCode,
      projectName: this.projectName,
      contents: $("#summernote").summernote("code"),
      attachFileList: this.attachFileList,
      useYn: false,
    };

    const checkTypeObj = () => {
      let retObj = {};
      // this.purchasePriceList.map((item) => {
      //   return item.getData();
      // });
      if (this.assetType === "H") {
        //용역일반구매
        // retObj = {
        //   purchasePriceList: this.purchasePriceList.map((item) => {
        //     return item.getData();
        //   }),
        // };
        retObj = {
          purchaseGoodsList: this.purchaseGoodsList.map((item, index) => {
            item.projectCode = this.projectCode;
            item.projectName = this.projectName;
            return item.getData();
          }),
          purchasePriceList: this.purchasePriceList.map((item) => {
            return item.getData();
          }),
        };
      } else {
        //인프라자산
        retObj = {
          purchaseGoodsList: this.purchaseGoodsList.map((item, index) => {
            item.projectCode = this.projectCode;
            item.projectName = this.projectName;

            return item.getData();
          }),
          purchasePriceList: this.purchasePriceList.map((item) => {
            return item.getData();
          }),
        };
      }
      return retObj;
    };

    return Object.assign({}, this.objRaw, obj, checkTypeObj());
  }

  resetPurchaseList() {
    this.purchasePriceList = [new DraftPurchasePriceListModel()];
    this.purchaseGoodsList = [new DraftPurchaseGoodsListModel()];
  }
  // 총 기안금액
  totalDraftingPrice() {
    let result = 0;
    this.purchasePriceList.forEach((item) => {
      result += getMoneyToNumber(item.draftingPrice);
    });
    return addCommaForMoney(result);
  }

  // 총 품목금액
  totalGoodsPrice() {
    let result = 0;
    this.purchaseGoodsList.forEach((item) => {
      result += getMoneyToNumber(item.totalPrice);
    });
    return addCommaForMoney(result);
  }

  // 기안금액 추가
  addPrice() {
    this.purchasePriceList.push(new DraftPurchasePriceListModel());
  }

  // 기안금액 삭제
  removePrice(itemToRemove) {
    this.purchasePriceList = this.purchasePriceList.filter((item) => item !== itemToRemove);
  }

  // 구매요청품목 추가
  addGood() {
    this.purchaseGoodsList.push(new DraftPurchaseGoodsListModel());
    this.purchasePriceList.push(new DraftPurchasePriceListModel());
  }
  // 구매요청품목 삭제
  removeGood(itemToRemove) {
    const removeGoodIndex = this.purchaseGoodsList.indexOf(itemToRemove);
    this.purchaseGoodsList = this.purchaseGoodsList.filter((item) => item !== itemToRemove);
    this.purchasePriceList.splice(removeGoodIndex, 1);
  }

  // 구매요청품목 금액 업데이트 - 기안금액 적용
  updatePrice(index) {
    console.log("index :>> ", index);
    const totalPrice = this.purchaseGoodsList[index].totalPrice;
    this.purchasePriceList[index].draftingPrice = totalPrice;
  }

  getAlertMessage() {
    if (_.isEmpty(this.purchaseContract)) return "구매요청정보를 연결해주세요";
    if (!this.title) return "기안명을 입력해주세요.";

    //this.assetType === 'I';
    if (this.purchaseGoodsList.some((item) => !item.itemCode)) {
      return "품명을 선택해주세요.";
    }
    if (this.purchaseGoodsList.some((item) => item.price <= 0)) {
      return "기안금액을 입력해주세요.";
    }
    if (!Boolean($("#summernote").summernote("code"))) return "상세내용을 입력해주세요.";
    return null;
  }
}
