<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>
      <div class="group_form">
        <DialougeItem v-if="item.itemCode" :itemCode="item.itemCode">
          <template v-slot:dialogueHead>
            <Input :value.sync="item.itemName" :isDisabled="true" :maxLength="200" />
          </template>
        </DialougeItem>
      </div>
    </td>
    <td><Input :value.sync="item.displayCode" :maxLength="200" :isDisabled="true" /><!----></td>
    <td>
      <Input :value.sync="item.assetsCode" :maxLength="200" :isDisabled="true" />
    </td>
    <td>
      <Input :isDisabled="true" :value.sync="item.unitValue" :maxLength="200" />
    </td>
    <td>
      <InputAutoMoney :isDisabled="true" :value.sync="item.quantity" :maxLength="200" />
    </td>
    <td>
      <InputAutoMoney :isDisabled="true" :value.sync="item.price" :maxLength="200" />
    </td>
    <td>
      <InputAutoMoney :value.sync="item.totalPrice" :isDisabled="true" :maxLength="200" />
    </td>
    <td>
      <Input :isDisabled="true" :value.sync="item.note" :maxLength="200" />
    </td>
    <!-- <td>
      <button
        class="btn_small btn_fourthly"
        :disabled="isDisabledRemove"
        @click="() => $emit('onClickRemove')"
      >
        삭제
      </button>
    </td> -->
  </tr>
</template>

<script>
import DialougeItem from "@/components/common/dialouge/DialougeItem";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Input from "@/components/common/input/Input";
import { getMoneyToNumber } from "@/utils/stringUtils";
import SelectBoxQuantity from "@/components/common/selectBox/SelectBoxQuantity";

export default {
  name: "DraftPriceInfraListLine",
  components: {
    DialougeItem,
    InputAutoMoney,
    Input,
    SelectBoxQuantity,
  },
  props: {
    model: Object,
    index: Number,
    item: Object,
    isDisabledRemove: Boolean,
    purchasePriceItem: Object,
    assetsCode: Boolean,
  },
  data() {
    return {
      currentList: [
        { code: "EA", name: "EA" },
        { code: "식", name: "식" },
        { code: "BOX", name: "Box" },
        { code: "직접입력", name: "직접입력" },
      ],
      currentSelected: { code: "EA", name: "EA" },
    };
  },
  computed: {
    computedPrice() {
      const { price, quantity } = this.item;

      if (!price || !quantity) return "0";

      const nUnitPrice = getMoneyToNumber(price);
      const nquantity = getMoneyToNumber(quantity);

      const nResult = nUnitPrice * nquantity;

      const strResult = String(nResult);

      return strResult;
    },
  },
  watch: {
    computedPrice(newVal, oldVal) {
      this.item.totalPrice = newVal;
      this.$emit("updatePrice", this.index);
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
</style>
