<template>
  <TableViewWithTitle title="기안 정보">
    <template v-slot:body>
      <tr>
        <th>기안명<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Input :maxLength="200" placeholder="기안명을 입력하세요." :value.sync="model.title" />
        </td>
      </tr>
      <tr>
        <th>기안자</th>
        <td>{{ model.userData.username }} ({{ model.userData.name }})</td>
        <th>기안자부서</th>
        <td>{{ model.userData.companyName }}</td>
      </tr>
      <!-- <tr>
        <th>
          구매구분
          <span class="ico_purchase ico_star">필수항목</span>
        </th>
        <td>
          <SelectBox
            :dataList="model.assetTypeList"
            :value.sync="model.assetType"
            @onChange="onChangeResetPurchaseList"
          />
        </td>
        <th></th>
        <td></td>
        <th>구매 카테고리</th>
        <td>
          <Search
            dropBoxMaxHeight="162px"
            keyName="itemCategoryNm"
            placeholder="품목군검색"
            :dataList="model.handleDataList"
            :value.sync="model.itemTitle"
            @setValue="onSetCategoryValue"
            @onInputKeyword="onInputKeywordCategory"
          />
        </td>
      </tr> -->
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import DateTimePicker from "@/components/common/calendar/DateTimePicker";
import Input from "@/components/common/input/Input";
import Search from "@/components/common/search/Search";
import SelectBox from "@/components/common/selectBox/SelectBox";

import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import ConnectEdit from "@/components/common/connect/ConnectEdit";
import ConnectView from "@/components/common/connect/ConnectView";

export default {
  name: "DraftInfo",
  components: {
    TableViewWithTitle,
    DateTimePicker,
    Input,
    Search,
    SelectBox,
    CategoryGroupIncludeData,
    ConnectEdit,
    ConnectView,
  },
  props: {
    model: Object,
  },
  mounted() {},
  methods: {
    onChangeResetPurchaseList() {
      this.model.resetPurchaseList();
    },
    onSetCategoryValue(item) {
      this.model.itemTitle = item;
      this.model.handleNum = this.model.originHandleDataList
        .filter((originItem) => originItem.itemTitle === item)
        .map((originItem) => {
          return originItem.handleNum;
        })
        .pop();
    },
    onInputKeywordCategory(value) {
      this.model.handleDataList = this.model.originHandleDataList
        .filter((item) => item.itemTitle.includes(value))
        .map((item) => item.itemTitle);
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>

<style scoped>
.td_ldap .btn_tag_ldap {
  margin: 2px 0 0 12px;
}
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
</style>
